import logoLink from '../resources/vzug.svg';

export const cssVariables = {
    active: '#CCFFCC',
    inactive: '#FFCCCC',

    primary_light: '#B3C7E7',
    primary: '#003494',
    primary_dark: '#001845',
    text_on_primary: '#fff',
    primary_transparent: '#F5F6FC',

    secondary: '#000',
    secondary_light: '#E6E6E6',
    text_on_secondary: 'white',
    secondary_transparent: '#F7F7F7',

    font_color: 'black',
    link_color: '#666666',

    font: 'Arial, Helvetica, sans-serif',

    border_radius: '0',
    border_width: '1px',
    border_style: 'solid',

    dropdown_background: '#B3C7E7',
    toggle_switch: 'rgb(120, 120, 120)',

    edit_color: '#003494',
    activate_color: '#A6A6A6',
    deactivate_color: '#A6A6A6',
    delete_color: '#000',  
    
};

export const logo = logoLink;
